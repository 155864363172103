<template>
	<div>
		<div class="advantageDistribution" v-if="windowWidth>=1200"
			:style="'background-image:' + `url(${require('@/assets/img/advantageDistribution.png')})`">
			<div class="ad_title">
				<!-- <div class="ad_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">Distribution</div> -->
				<div class="ad_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
					{{$t('homeAbout.title2')}}</div>
			</div>

			<div style="margin-top: 20px;" class="wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s"
				data-wow-offset="0">
				<el-carousel height="400px" :autoplay="false">
					<el-carousel-item v-for="(item,index) in partner" :key="index">
						<div class="ad_carousel">
							<div class="ad_ca_div" v-for="(item2,index2) in item" :key="index2">
								<el-image class="ad_ca_div_image" :src="item2.brandImage" :fit="'cover'"></el-image>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
		<div class="advantageDistribution" v-if="windowWidth<=1199"
			:style="'background-image:' + `url(${require('@/assets/img/advantageDistribution.png')})`">
			<div class="ad_title">
				<!-- <div class="ad_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">Distribution</div> -->
				<div class="ad_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
					{{$t('homeAbout.title2')}}</div>
			</div>
		
			<div style="margin-top: 20px;" class="wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s"
				data-wow-offset="0">
				<el-carousel :height="windowWidth>=768 ? '550px' : '550px' " :autoplay="false">
					<el-carousel-item v-for="(item,index) in partner" :key="index">
						<div class="ad_carousel">
							<div class="ad_ca_div" v-for="(item2,index2) in item" :key="index2">
								<el-image class="ad_ca_div_image" :src="item2.brandImage" :fit="'cover'"></el-image>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		brandList
	} from '@/api/home/index.js';
	import { brandInfo } from './data.js'
	import WOW from "wowjs";
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				//合作伙伴列表
				partner: [],
			}
		},

		created() {

		},

		watch: {

		},

		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: true
			});
			wow.init();
			this.listData();
		},

		computed: {

		},

		methods: {
			//-------------品牌列表接口-----------------
			listData: function() {
				var a = brandInfo()
				for (var i = 0; i < a.length; i += 21) {
					this.partner.push(a.slice(i, i + 21))
				}
				// brandList().then(res => {
					
				// 	console.log(this.partner)
				// })
			},
		}
	}
</script>

<style lang="less" scoped>
	@font-face {
		font-family: 'BigJohn';
		src: url('../../../assets/big-john-woff2-2.ttf') format('truetype');
	}

	@font-face {
		font-family: 'zhenyan';
		src: url('../../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}


	@media only screen and (min-width:1920px) {
		.advantageDistribution {
			width: 100%;
			padding: 120px 200px 60px 200px;
			position: relative;
			z-index: 9;

			.ad_title {
				text-align: center;

				.ad_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}

				.ad_title_cn {
					font-size: 40px;
					color: #fff;
					font-family: zhenyan;
				}
			}
		}

		.ad_carousel {
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.ad_ca_div {
				width: calc((100% - 12vw) / 8);
				margin: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				// border: 1px solid #ccc;

				.ad_ca_div_image {
					text-align: center;
					position: relative;
					width: 100%;
					transition: all 0.5s;
				}

				.ad_ca_div_image:hover {
					text-align: center;
					position: relative;
					transform: scale(1.3, 1.3);
				}
			}
		}
	}

	@media only screen and (max-width:1920px) {
		.advantageDistribution {
			width: 100%;
			padding: 120px 200px 60px 200px;
			position: relative;
			z-index: 9;

			.ad_title {
				text-align: center;

				.ad_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}

				.ad_title_cn {
					font-size: 40px;
					color: #fff;
					font-family: zhenyan;
				}
			}
		}

		.ad_carousel {
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.ad_ca_div {
				width: calc((100% - 12vw) / 8);
				margin: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				// border: 1px solid #ccc;

				.ad_ca_div_image {
					text-align: center;
					position: relative;
					width: 100%;
					transition: all 0.5s;
				}

				.ad_ca_div_image:hover {
					text-align: center;
					position: relative;
					transform: scale(1.3, 1.3);
				}
			}
		}
	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.advantageDistribution {
			width: 100%;
			padding: 120px 100px 60px 100px;
			position: relative;
			z-index: 9;

			.ad_title {
				text-align: center;

				.ad_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}

				.ad_title_cn {
					font-size: 40px;
					color: #fff;
					font-family: zhenyan;
				}
			}
		}

		.ad_carousel {
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.ad_ca_div {
				width: calc((100% - 12vw) / 8);
				margin: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				// border: 1px solid #ccc;

				.ad_ca_div_image {
					text-align: center;
					position: relative;
					width: 100%;
					transition: all 0.5s;
				}

				.ad_ca_div_image:hover {
					text-align: center;
					position: relative;
					transform: scale(1.3, 1.3);
				}
			}
		}
	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.advantageDistribution {
			width: 100%;
			padding: 120px 60px 60px 60px;
			position: relative;
			z-index: 9;

			.ad_title {
				text-align: center;

				.ad_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}

				.ad_title_cn {
					font-size: 40px;
					color: #fff;
					font-family: zhenyan;
				}
			}
		}

		.ad_carousel {
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.ad_ca_div {
				width: calc((100% - 12vw) / 8);
				margin: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				// border: 1px solid #ccc;

				.ad_ca_div_image {
					text-align: center;
					position: relative;
					width: 100%;
					transition: all 0.5s;
				}

				.ad_ca_div_image:hover {
					text-align: center;
					position: relative;
					transform: scale(1.3, 1.3);
				}
			}
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.advantageDistribution {
				width: 100%;
				padding: 120px 60px 60px 60px;
				position: relative;
				z-index: 9;
		
				.ad_title {
					text-align: center;
		
					.ad_title_en {
						display: inline-block;
						font-size: 62px;
						line-height: 1.4;
						color: transparent;
						font-family: BigJohn;
						text-transform: uppercase;
						background: linear-gradient(to right, #621ed2, #3066f5);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						text-fill-color: transparent;
						font-weight: bold;
					}
		
					.ad_title_cn {
						font-size: 40px;
						color: #fff;
						font-family: zhenyan;
					}
				}
			}
		
			.ad_carousel {
				height: 500px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
		
				.ad_ca_div {
					width: calc((100% - 12vw) / 5);
					margin: 10px 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					// border: 1px solid #ccc;
		
					.ad_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
		
					.ad_ca_div_image:hover {
						text-align: center;
						position: relative;
						transform: scale(1.3, 1.3);
					}
				}
			}
		
	}

	@media only screen and (max-width:767px) {
		
			.advantageDistribution {
				width: 100%;
				padding: 20px;
				position: relative;
				z-index: 9;
		
				.ad_title {
					text-align: center;
		
					.ad_title_en {
						display: inline-block;
						font-size: 62px;
						line-height: 1.4;
						color: transparent;
						font-family: BigJohn;
						text-transform: uppercase;
						background: linear-gradient(to right, #621ed2, #3066f5);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						text-fill-color: transparent;
						font-weight: bold;
					}
		
					.ad_title_cn {
						font-size: 40px;
						color: #fff;
						font-family: zhenyan;
					}
				}
			}
		
			.ad_carousel {
				height: 500px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
		
				.ad_ca_div {
					width: calc((100% - 12vw) / 4);
					margin: 10px 6px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					// border: 1px solid #ccc;
		
					.ad_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
		
					.ad_ca_div_image:hover {
						text-align: center;
						position: relative;
						transform: scale(1.3, 1.3);
					}
				}
			}
		
	}
</style>
