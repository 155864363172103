<template>
	<div>
		<div class="agentBrand">
			<!-- <div class="ab_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="100">Agency brand</div> -->
			<div class="ab_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="100">{{$t('productCenter.title1')}}</div>
			
			<div class=" wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="100">
				<el-carousel height="200px" :interval="6000">
				  <el-carousel-item v-for="(item,index) in partner" :key="index">
				    <div class="ab_carousel">
				    	<div class="ab_ca_div" v-for="(item2,index2) in item" :key="index2">
				    		<el-image class="ab_ca_div_image" :src="item2.brandImage" :fit="'cover'"></el-image>
				    	</div>
				    </div>
				  </el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		brandList
	} from '@/api/home/index.js';
	import { agentInfo } from "./data.js" 
	import WOW from "wowjs";
	export default {
		data() {
			return {
				//合作伙伴列表
				partner:[],
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
			this.listData();
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//-------------品牌列表接口-----------------
			listData:function(){
				var a = agentInfo()
				for(var i = 0; i < a.length; i += 21){
					this.partner.push(a.slice(i,i+21))
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.agentBrand{
		text-align: center;
		padding-top: 80px;
		padding-bottom: 80px;
		
		.ab_en{
			display:inline-block;
			font-size: 62px;
			line-height: 1.4;
			color: transparent;
			font-family:BigJohn;
			text-transform: uppercase;
			background: linear-gradient(to right, #621ed2, #3066f5);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			text-fill-color: transparent;
			font-weight: bold;
		}
		
		.ab_cn{
			font-size: 40px;
			color: #333;
			font-family: zhenyan;
		}
		
		.ab_carousel{
			height: 200px;
			padding-top: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			.ab_ca_div{
				width: 16%;
				margin: 10px 20px;
				display: flex;
				height: 100%;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				border: 1px solid #ccc;
				
				.ab_ca_div_image{
					text-align: center;
					position: relative;
					width: 100%;
					transition: all 0.5s;
				}
				
				.ab_ca_div_image:hover{
					text-align: center;
					position: relative;
					transform: scale(1.3,1.3);
				}
			}
		}
	}
</style>