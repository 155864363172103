<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="subpageTop">
				<el-image class="st_background" :src="image" :fit="'cover'"></el-image>
				<div class="st_ba_content">
					<div class="st_ba_ct_title">{{title}}</div>
					<div class="st_ba_ct_breadcrumb">
						<div>{{$t('subpageTop.text1')}}：</div>
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item style="color: #fff;" :to="{ path: '/home' }">{{$t('subpageTop.text2')}}</el-breadcrumb-item>
							<el-breadcrumb-item style="color: #fff;">{{breadcrumbText}}</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="circle">
						<i class="el-icon-arrow-down icon-down"></i>
					</div>
				</div>
			</div>
			
			<div class="subpageTop_list">
				<div class="st_li_div wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.5s" data-wow-offset="0" v-for="(item,index) in list" :key="index" @click="anchorPoint(index)">
					<div class="st_li_mask"></div>
					<div class="st_li_name">{{item.name}}</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="subpageTop">
				<el-image class="st_background" :src="image" :fit="'cover'"></el-image>
				<div class="st_ba_content">
					<div class="st_ba_ct_title">{{title}}</div>
					<!-- <div class="st_ba_ct_breadcrumb">
						<div>{{$t('subpageTop.text1')}}：</div>
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item style="color: #fff;" :to="{ path: '/home' }">{{$t('subpageTop.text2')}}</el-breadcrumb-item>
							<el-breadcrumb-item style="color: #fff;">{{breadcrumbText}}</el-breadcrumb-item>
						</el-breadcrumb>
					</div> -->
					<div class="circle">
						<i class="el-icon-arrow-down icon-down"></i>
					</div>
				</div>
			</div>
			
			<div class="subpageTop_list">
				<div class="st_li_div wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.5s" data-wow-offset="0" v-for="(item,index) in list" :key="index" @click="anchorPoint(index)">
					<div class="st_li_mask"></div>
					<div class="st_li_name">{{item.name}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from "wowjs";
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
			title:{
				type:String,
				default:"",
			},
			image:{
				type:String,
				default:"",
			},
			breadcrumbText:{
				type:String,
				default:"",
			},
			list:{
				type:Array,
				default:()=>[]
			}
		},
		data() {
			return {
				
			}
		},

		created() {

		},

		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
		},

		watch: {

		},

		computed: {

		},

		methods: {
			//-------------------锚点跳转-------------------
			anchorPoint:function(index){
				this.$emit('anchorPoint',index)
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.el-icon-arrow-right {
			color: #fff;
		}
		
		/* 不被选中时的颜色 */
		.el-breadcrumb ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		/* 被选中时的颜色 */
		.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		.icon-down {
			color: #fff;
			position: absolute;
			bottom: 15%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			margin-left: -1%;
			animation-name: beat;
			/*动画名称*/
			animation-duration: .5s;
			/*设置秒数*/
			animation-timing-function: linear;
			/*速度曲线*/
			animation-iteration-count: infinite;
			/*播放次数*/
			animation-direction: alternate;
			/*逆向播放*/
			animation-play-state: running;
			/*正在运行*/
		}
		
		@keyframes beat {
			0% {
				bottom: 15%;
			}
		
			100% {
				bottom: 14%;
			}
		}
		
		.subpageTop_list{
			border-bottom: 1px solid #ddd;
			display: flex;
			justify-content: center;
			// overflow: hidden;
			position: relative;
		}
		
		.st_li_div{
			padding: 20px 40px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
		}
		
		.st_li_mask{
			z-index: 4;
			background: linear-gradient(to right, rgba(98, 30, 210), rgba(48, 102, 245));
			width: 100%;
			height: 100%;
			bottom: 0;
			left: -100%;
			transition: all 0.5s;
			position: absolute;
		}
		
		.st_li_div:hover .st_li_mask {
			left: 0;
		}
		
		.st_li_name{
			position: relative;
			z-index: 99;
			color: #000;
			font-size: 18px;
			font-weight: bold;
			transition: all 0.5s;
		}
		
		.st_li_div:hover .st_li_name {
			color: #fff;
		}
		
		.subpageTop{
			position: relative;
			height: 30vw;
			min-height: 200px;
			overflow: hidden;
			width: 100%;
			background: red;
			margin-top: 50px;
			
			.st_background{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.st_ba_content{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 79%;
				transform: translate(-50%,-50%);
				z-index: 9;
				
				.st_ba_ct_title{
					color: #fff;
					font-size: 2.5vw;
					font-weight: bold;
				}
				
				.st_ba_ct_breadcrumb{
					display: flex;
					color: #fff;
					align-items: center;
					font-size: 16px;
					padding: 20px 0;
				}
				
				.circle{
					width: 2.9vw;
					min-width: 30px;
					height: 2.9vw;
					min-height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid #b6b6b6;
					color: #000;
					font-size: 14px;
					border-radius: 50%;
					margin-right: 15px;
					transition: all 0.5s ease;
					position: relative;
					margin-top: 30px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-arrow-right {
			color: #fff;
		}
		
		/* 不被选中时的颜色 */
		.el-breadcrumb ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		/* 被选中时的颜色 */
		.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		.icon-down {
			color: #fff;
			position: absolute;
			bottom: 15%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			margin-left: -1%;
			animation-name: beat;
			/*动画名称*/
			animation-duration: .5s;
			/*设置秒数*/
			animation-timing-function: linear;
			/*速度曲线*/
			animation-iteration-count: infinite;
			/*播放次数*/
			animation-direction: alternate;
			/*逆向播放*/
			animation-play-state: running;
			/*正在运行*/
		}
		
		@keyframes beat {
			0% {
				bottom: 15%;
			}
		
			100% {
				bottom: 14%;
			}
		}
		
		.subpageTop_list{
			border-bottom: 1px solid #ddd;
			display: flex;
			justify-content: center;
			// overflow: hidden;
			position: relative;
		}
		
		.st_li_div{
			padding: 20px 40px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
		}
		
		.st_li_mask{
			z-index: 4;
			background: linear-gradient(to right, rgba(98, 30, 210), rgba(48, 102, 245));
			width: 100%;
			height: 100%;
			bottom: 0;
			left: -100%;
			transition: all 0.5s;
			position: absolute;
		}
		
		.st_li_div:hover .st_li_mask {
			left: 0;
		}
		
		.st_li_name{
			position: relative;
			z-index: 99;
			color: #000;
			font-size: 18px;
			font-weight: bold;
			transition: all 0.5s;
		}
		
		.st_li_div:hover .st_li_name {
			color: #fff;
		}
		
		.subpageTop{
			position: relative;
			height: 30vw;
			min-height: 200px;
			overflow: hidden;
			width: 100%;
			background: red;
			margin-top: 50px;
			
			.st_background{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.st_ba_content{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 79%;
				transform: translate(-50%,-50%);
				z-index: 9;
				
				.st_ba_ct_title{
					color: #fff;
					font-size: 2.5vw;
					font-weight: bold;
				}
				
				.st_ba_ct_breadcrumb{
					display: flex;
					color: #fff;
					align-items: center;
					font-size: 16px;
					padding: 20px 0;
				}
				
				.circle{
					width: 2.9vw;
					min-width: 30px;
					height: 2.9vw;
					min-height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid #b6b6b6;
					color: #000;
					font-size: 14px;
					border-radius: 50%;
					margin-right: 15px;
					transition: all 0.5s ease;
					position: relative;
					margin-top: 30px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-arrow-right {
			color: #fff;
		}
		
		/* 不被选中时的颜色 */
		.el-breadcrumb ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		/* 被选中时的颜色 */
		.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		.icon-down {
			color: #fff;
			position: absolute;
			bottom: 15%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			margin-left: -1%;
			animation-name: beat;
			/*动画名称*/
			animation-duration: .5s;
			/*设置秒数*/
			animation-timing-function: linear;
			/*速度曲线*/
			animation-iteration-count: infinite;
			/*播放次数*/
			animation-direction: alternate;
			/*逆向播放*/
			animation-play-state: running;
			/*正在运行*/
		}
		
		@keyframes beat {
			0% {
				bottom: 15%;
			}
		
			100% {
				bottom: 14%;
			}
		}
		
		.subpageTop_list{
			border-bottom: 1px solid #ddd;
			display: flex;
			justify-content: center;
			// overflow: hidden;
			position: relative;
		}
		
		.st_li_div{
			padding: 20px 40px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
		}
		
		.st_li_mask{
			z-index: 4;
			background: linear-gradient(to right, rgba(98, 30, 210), rgba(48, 102, 245));
			width: 100%;
			height: 100%;
			bottom: 0;
			left: -100%;
			transition: all 0.5s;
			position: absolute;
		}
		
		.st_li_div:hover .st_li_mask {
			left: 0;
		}
		
		.st_li_name{
			position: relative;
			z-index: 99;
			color: #000;
			font-size: 18px;
			font-weight: bold;
			transition: all 0.5s;
		}
		
		.st_li_div:hover .st_li_name {
			color: #fff;
		}
		
		.subpageTop{
			position: relative;
			height: 30vw;
			min-height: 200px;
			overflow: hidden;
			width: 100%;
			background: red;
			margin-top: 50px;
			
			.st_background{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.st_ba_content{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 79%;
				transform: translate(-50%,-50%);
				z-index: 9;
				
				.st_ba_ct_title{
					color: #fff;
					font-size: 2.5vw;
					font-weight: bold;
				}
				
				.st_ba_ct_breadcrumb{
					display: flex;
					color: #fff;
					align-items: center;
					font-size: 16px;
					padding: 20px 0;
				}
				
				.circle{
					width: 2.9vw;
					min-width: 30px;
					height: 2.9vw;
					min-height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid #b6b6b6;
					color: #000;
					font-size: 14px;
					border-radius: 50%;
					margin-right: 15px;
					transition: all 0.5s ease;
					position: relative;
					margin-top: 30px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-arrow-right {
			color: #fff;
		}
		
		/* 不被选中时的颜色 */
		.el-breadcrumb ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		/* 被选中时的颜色 */
		.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		.icon-down {
			color: #fff;
			position: absolute;
			bottom: 15%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			margin-left: -1%;
			animation-name: beat;
			/*动画名称*/
			animation-duration: .5s;
			/*设置秒数*/
			animation-timing-function: linear;
			/*速度曲线*/
			animation-iteration-count: infinite;
			/*播放次数*/
			animation-direction: alternate;
			/*逆向播放*/
			animation-play-state: running;
			/*正在运行*/
		}
		
		@keyframes beat {
			0% {
				bottom: 15%;
			}
		
			100% {
				bottom: 14%;
			}
		}
		
		.subpageTop_list{
			border-bottom: 1px solid #ddd;
			display: flex;
			justify-content: center;
			// overflow: hidden;
			position: relative;
		}
		
		.st_li_div{
			padding: 20px 40px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
		}
		
		.st_li_mask{
			z-index: 4;
			background: linear-gradient(to right, rgba(98, 30, 210), rgba(48, 102, 245));
			width: 100%;
			height: 100%;
			bottom: 0;
			left: -100%;
			transition: all 0.5s;
			position: absolute;
		}
		
		.st_li_div:hover .st_li_mask {
			left: 0;
		}
		
		.st_li_name{
			position: relative;
			z-index: 99;
			color: #000;
			font-size: 18px;
			font-weight: bold;
			transition: all 0.5s;
		}
		
		.st_li_div:hover .st_li_name {
			color: #fff;
		}
		
		.subpageTop{
			position: relative;
			height: 30vw;
			min-height: 200px;
			overflow: hidden;
			width: 100%;
			background: red;
			margin-top: 50px;
			
			.st_background{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.st_ba_content{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 79%;
				transform: translate(-50%,-50%);
				z-index: 9;
				
				.st_ba_ct_title{
					color: #fff;
					font-size: 2.5vw;
					font-weight: bold;
				}
				
				.st_ba_ct_breadcrumb{
					display: flex;
					color: #fff;
					align-items: center;
					font-size: 16px;
					padding: 20px 0;
				}
				
				.circle{
					width: 2.9vw;
					min-width: 30px;
					height: 2.9vw;
					min-height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid #b6b6b6;
					color: #000;
					font-size: 14px;
					border-radius: 50%;
					margin-right: 15px;
					transition: all 0.5s ease;
					position: relative;
					margin-top: 30px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-arrow-right {
			color: #fff;
		}
		
		/* 不被选中时的颜色 */
		.el-breadcrumb ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		/* 被选中时的颜色 */
		.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
			color: #fff !important;
			font-size: 16px !important;
		}
		
		.icon-down {
			color: #fff;
			position: absolute;
			bottom: 15%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			margin-left: -1%;
			animation-name: beat;
			/*动画名称*/
			animation-duration: .5s;
			/*设置秒数*/
			animation-timing-function: linear;
			/*速度曲线*/
			animation-iteration-count: infinite;
			/*播放次数*/
			animation-direction: alternate;
			/*逆向播放*/
			animation-play-state: running;
			/*正在运行*/
		}
		
		@keyframes beat {
			0% {
				bottom: 15%;
			}
		
			100% {
				bottom: 14%;
			}
		}
		
		.subpageTop_list{
			border-bottom: 1px solid #ddd;
			display: flex;
			justify-content: center;
			// overflow: hidden;
			position: relative;
		}
		
		.st_li_div{
			padding: 10px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
		}
		
		.st_li_mask{
			z-index: 4;
			background: linear-gradient(to right, rgba(98, 30, 210), rgba(48, 102, 245));
			width: 100%;
			height: 100%;
			bottom: 0;
			left: -100%;
			transition: all 0.5s;
			position: absolute;
		}
		
		.st_li_div:hover .st_li_mask {
			left: 0;
		}
		
		.st_li_name{
			font-size: 8px;
			position: relative;
			z-index: 99;
			color: #000;
			font-weight: bold;
			transition: all 0.5s;
		}
		
		.st_li_div:hover .st_li_name {
			color: #fff;
		}
		
		.subpageTop{
			position: relative;
			height: 30vw;
			min-height: 200px;
			overflow: hidden;
			width: 100%;
			background: red;
			margin-top: 50px;
			
			.st_background{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.st_ba_content{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 79%;
				transform: translate(-50%,-50%);
				z-index: 9;
				
				.st_ba_ct_title{
					color: #fff;
					font-size: 2.5vw;
					font-weight: bold;
				}
				
				.st_ba_ct_breadcrumb{
					display: flex;
					color: #fff;
					align-items: center;
					font-size: 16px;
					padding: 20px 0;
				}
				
				.circle{
					width: 2.9vw;
					min-width: 30px;
					height: 2.9vw;
					min-height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid #b6b6b6;
					color: #000;
					font-size: 14px;
					border-radius: 50%;
					margin-right: 15px;
					transition: all 0.5s ease;
					position: relative;
					margin-top: 30px;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		
			.el-icon-arrow-right {
				color: #fff;
			}
			
			/* 不被选中时的颜色 */
			.el-breadcrumb ::v-deep .el-breadcrumb__inner {
				color: #fff !important;
				font-size: 16px !important;
			}
			
			/* 被选中时的颜色 */
			.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
				color: #fff !important;
				font-size: 16px !important;
			}
			
			.icon-down {
				color: #fff;
				position: absolute;
				bottom: 15%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				margin-left: -1%;
				animation-name: beat;
				/*动画名称*/
				animation-duration: .5s;
				/*设置秒数*/
				animation-timing-function: linear;
				/*速度曲线*/
				animation-iteration-count: infinite;
				/*播放次数*/
				animation-direction: alternate;
				/*逆向播放*/
				animation-play-state: running;
				/*正在运行*/
			}
			
			@keyframes beat {
				0% {
					bottom: 15%;
				}
			
				100% {
					bottom: 14%;
				}
			}
			
			.subpageTop_list{
				border-bottom: 1px solid #ddd;
				display: flex;
				justify-content: center;
				// overflow: hidden;
				position: relative;
			}
			
			.st_li_div{
				text-align: center;
				padding: 10px;
				overflow: hidden;
				position: relative;
				cursor: pointer;
			}
			
			.st_li_mask{
				z-index: 4;
				background: linear-gradient(to right, rgba(98, 30, 210), rgba(48, 102, 245));
				width: 100%;
				height: 100%;
				bottom: 0;
				left: -100%;
				transition: all 0.5s;
				position: absolute;
			}
			
			.st_li_div:hover .st_li_mask {
				left: 0;
			}
			
			.st_li_name{
				font-size: 8px;
				position: relative;
				z-index: 99;
				color: #000;
				font-weight: bold;
				transition: all 0.5s;
			}
			
			.st_li_div:hover .st_li_name {
				color: #fff;
			}
			
			.subpageTop{
				position: relative;
				height: 30vw;
				min-height: 200px;
				overflow: hidden;
				width: 100%;
				background: red;
				margin-top: 50px;
				
				.st_background{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				
				.st_ba_content{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 79%;
					transform: translate(-50%,-50%);
					z-index: 9;
					
					.st_ba_ct_title{
						color: #fff;
						font-size: 2.5vw;
						font-weight: bold;
					}
					
					.st_ba_ct_breadcrumb{
						display: flex;
						color: #fff;
						align-items: center;
						font-size: 16px;
						padding: 20px 0;
					}
					
					.circle{
						width: 2.9vw;
						min-width: 30px;
						height: 2.9vw;
						min-height: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						border: 1px solid #b6b6b6;
						color: #000;
						font-size: 14px;
						border-radius: 50%;
						margin-right: 15px;
						transition: all 0.5s ease;
						position: relative;
						margin-top: 30px;
					}
				}
			}
		
	}
</style>
